



















import StoreFront from '@/store/storeFront';
import { storeFrontStore } from '@/store/typed';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class StoreFrontPreviewTopbar extends Vue {

  get storeFront() {
    return storeFrontStore.storeFront;
  }

  private goToPreview() {
    this.$router.push({
      name: 'store_front_preview_dashboard',
      params: { id: this.storeFront.id }
    });
  }
}
