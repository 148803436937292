







import { Vue, Component } from "vue-property-decorator";
import StoreFrontPreviewTopbar from '@/components/StoreFront/PreviewTopbar.vue';

@Component({
  components: {
    StoreFrontPreviewTopbar
  }
})
export default class StoreFrontContentIndex extends Vue {
}
